import { getAccessToken } from "@privy-io/react-auth";
import {
	queryOptions,
	useQuery,
	useSuspenseQuery,
} from "@tanstack/react-query";
import { z } from "zod";
import { fetcher } from "../lib/fetcher";
import type { QueryOptions } from "./types";

export type UseMeReturn = Awaited<ReturnType<typeof fn>>;
type Options = QueryOptions<UseMeReturn>;

async function fn() {
	const token = await getAccessToken();

	if (!token) {
		throw new Error("No Privy token");
	}

	return fetcher(
		`${import.meta.env.VITE_API_URL}/dashboard/me`,
		z.object({
			email: z.string(),
			legalName: z.string().nullable(),
			createdAt: z.coerce.date(),
			capabilities: z.object({
				canWithdrawBankAccount: z.boolean(),
				canWithdrawWallet: z.boolean(),
			}),
		}),
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		},
	);
}

export function meOptions(options: Options = {}) {
	return queryOptions({
		queryKey: ["me"],
		queryFn: () => fn(),
		staleTime: 10 * 1000,
		...options,
	});
}

export function useMe(options: Options = {}) {
	const { data, ...query } = useQuery(meOptions(options));

	return {
		me: data,
		...query,
	};
}

export function useSuspenseMe(options: Options = {}) {
	const { data, ...query } = useSuspenseQuery(meOptions(options));

	return {
		me: data,
		...query,
	};
}
