import { forwardRef } from "react";
import { cn } from "../../lib/cn";

const Select = forwardRef<HTMLSelectElement, React.ComponentProps<"select">>(
	({ className, children, ...props }, ref) => {
		return (
			<select
				className={cn(
					"w-full px-3 py-1.5 rounded-lg border border-neutral-200 transition-all duration-200 focus-visible:outline-none focus-visible:border-ring focus-visible:ring-[3px] focus-visible:ring-inflow-700/40 appearance-none",
					className,
				)}
				ref={ref}
				{...props}
			>
				{children}
			</select>
		);
	},
);

Select.displayName = "Select";

export { Select };
