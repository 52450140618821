import { zodResolver } from "@hookform/resolvers/zod";
import {
	createRoute,
	redirect,
	useNavigate,
	useSearch,
} from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import z from "zod";
import { useCreateMerchant } from "../api/mutations/use-create-merchant";
import { InflowIcon } from "../assets/inflow";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Select } from "../components/ui/select";
import { countryListAllIsoData } from "../data/countries";
import { queryClient, rootRoute } from "../layouts/_root";
import { getUser } from "../lib/get-user";

const merchantSearchParamsSchema = z.object({
	next: z.string().optional(),
});

const formSchema = z.object({
	merchant: z.string().min(3, "Company name is too short"),
	country: z.string(),
});

type FormValues = z.infer<typeof formSchema>;

export const merchantRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/merchant",
	component: MerchantPage,
	loaderDeps: ({ search: { next } }) => ({ next }),
	loader: async ({ deps, context }) => {
		const user = await getUser(context.accessToken);

		if (!user || !context.accessToken) {
			throw redirect({
				to: "/login",
				search: {
					next: deps.next || "/",
				},
			});
		}

		if (user.legalName !== null) {
			throw redirect({
				to: deps.next || "/",
			});
		}

		return {
			user,
			token: context.accessToken,
		};
	},
	validateSearch: merchantSearchParamsSchema,
});

function MerchantPage() {
	const navigate = useNavigate({ from: "/merchant" });
	const search = useSearch({ from: "/merchant" });
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormValues>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			country: "FR",
		},
	});
	const { mutateAsync: createMerchant } = useCreateMerchant({
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: ["me"] });

			navigate({
				to: search.next || "/",
			});
		},
	});

	function onSubmit(data: FormValues) {
		createMerchant(data);
	}

	return (
		<div className="min-h-screen flex flex-row">
			<main className="flex-1 md:min-w-[32rem] md:max-w-2xl h-screen py-8 md:py-12 flex flex-col items-center md:justify-center">
				<div className="w-full px-4 md:max-w-md flex flex-col items-start space-y-12">
					<InflowIcon className="w-20 h-8 fill-black" />
					<form
						className="w-full flex flex-col space-y-4"
						onSubmit={handleSubmit(onSubmit)}
					>
						<h1 className="text-xl font-medium">Setting up your account</h1>
						<div className="flex flex-col space-y-1">
							<label
								htmlFor="merchant"
								className="text-sm text-neutral-700 font-medium"
							>
								Company name
							</label>
							<Input
								id="merchant"
								type="text"
								autoFocus
								// className="w-full px-3 py-1.5 text-sm border border-neutral-200 hover:border-neutral-300 focus:border-neutral-300 rounded-md transition-colors duration-300 ease-in-out outline-none focus-visible:ring-2 focus-visible:ring-offset-2 ring-inflow-600/50"
								{...register("merchant")}
							/>
							{errors.merchant && (
								<span className="text-sm text-red-500">
									{errors.merchant.message}
								</span>
							)}
						</div>
						<div className="flex flex-col space-y-1">
							<label
								htmlFor="country"
								className="text-sm text-neutral-700 font-medium"
							>
								Country
							</label>
							<Select id="country" {...register("country")}>
								{countryListAllIsoData.map(({ name, code }) => (
									<option key={code} value={code}>
										{name} ({code})
									</option>
								))}
							</Select>
							{errors.country && (
								<span className="text-sm text-red-500">
									{errors.country.message}
								</span>
							)}
						</div>
						<Button
							type="submit"
							color="inflow"
							intent="solid"
							className="w-full !py-2"
						>
							Submit
						</Button>
					</form>
				</div>
			</main>
			<div className="flex-1 relative hidden md:block">
				<img
					src="/static/bg.png"
					alt="Login background"
					className="h-full w-full object-cover"
					fetchPriority="high"
				/>
			</div>
		</div>
	);
}
