import { getAccessToken } from "@privy-io/react-auth";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { fetcher } from "../../lib/fetcher";
import type { MutationOptions } from "../types";

type Data = {
	merchant: string;
	country: string;
};

type Options = MutationOptions<Awaited<ReturnType<typeof fn>>, Data>;

async function fn({ merchant, country }: Data) {
	return fetcher(
		`${import.meta.env.VITE_API_URL}/dashboard/merchant`,
		z.object({
			ok: z.boolean(),
		}),
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${await getAccessToken()}`,
			},
			body: JSON.stringify({
				legalName: merchant,
				country,
			}),
		},
	);
}

export function useCreateMerchant(options: Options = {}) {
	return useMutation({
		mutationKey: ["create-merchant"],
		mutationFn: fn,
		...options,
	});
}
