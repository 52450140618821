import { zodResolver } from "@hookform/resolvers/zod";
import { Link, useNavigate, useSearch } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { CheckIcon } from "../../assets/check";
import { Button } from "../ui/button";

const formValuesSchema = z.object({
	description: z.string().min(3, "Name must me at least 3 characters long"),
	amount: z.coerce.number().gte(0.01, "Amount must be greater than 0"),
	quantity: z.coerce.number().gte(1, "Must have one or more items"),
});

type FormValues = z.infer<typeof formValuesSchema>;

export function AddProductForm() {
	const navigate = useNavigate({
		from: "/products/add",
	});
	const { edit, ...search } = useSearch({
		from: "/empty/products/add",
	});
	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<FormValues>({
		resolver: zodResolver(formValuesSchema),
		mode: "onSubmit",
		reValidateMode: "onChange",
		defaultValues: edit
			? (search.products ?? []).find((prod) => JSON.stringify(prod) === edit)
			: {
					quantity: 1,
				},
	});

	async function onSubmit(data: FormValues) {
		const products = [];

		if (edit) {
			let hasPushedEdit = false;

			for (const prod of search.products ?? []) {
				if (JSON.stringify(prod) === edit && !hasPushedEdit) {
					hasPushedEdit = true;
					products.push(data);
				} else {
					products.push(prod);
				}
			}
		} else {
			products.push(...(search.products ?? []));
			products.push(data);
		}

		navigate({
			to: "/links/new",
			search: {
				...search,
				products,
			},
		});
	}

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="w-full flex flex-col space-y-6 max-w-3xl mx-auto"
		>
			<div className="flex flex-col space-y-3">
				<div className="flex flex-col space-y-2">
					<div className="flex flex-col">
						<label htmlFor="amount" className="text-neutral-700">
							Amount
						</label>
						{errors.amount && (
							<span className="text-sm text-red-500">
								{errors.amount.message}
							</span>
						)}
					</div>
					<input
						id="amount"
						type="text"
						placeholder="19.99"
						className="w-full px-4 py-2 md:px-3 md:py-1.5 text-sm border border-neutral-200 hover:border-neutral-300 focus:border-neutral-300 outline-none rounded-md transition-colors duration-300 ease-in-out"
						{...register("amount")}
					/>
				</div>
				<div className="flex flex-col space-y-2">
					<div className="flex flex-col">
						<label htmlFor="description" className="text-neutral-700">
							Description
						</label>
						{errors.description && (
							<span className="text-sm text-red-500">
								{errors.description.message}
							</span>
						)}
					</div>
					<input
						id="description"
						type="text"
						placeholder="Green shirt"
						className="w-full px-4 py-2 md:px-3 md:py-1.5 text-sm border border-neutral-200 hover:border-neutral-300 focus:border-neutral-300 outline-none rounded-md transition-colors duration-300 ease-in-out"
						{...register("description")}
					/>
				</div>
				<div className="flex flex-col space-y-2">
					<div className="flex flex-col">
						<label htmlFor="quantity" className="text-neutral-700">
							Quantity
						</label>
						{errors.quantity && (
							<span className="text-sm text-red-500">
								{errors.quantity.message}
							</span>
						)}
					</div>
					<input
						id="quantity"
						type="text"
						placeholder="1"
						className="w-full px-4 py-2 md:px-3 md:py-1.5 text-sm border border-neutral-200 hover:border-neutral-300 focus:border-neutral-300 outline-none rounded-md transition-colors duration-300 ease-in-out"
						{...register("quantity")}
					/>
				</div>
			</div>
			<div className="w-full flex space-x-6">
				<Button
					type="submit"
					color="inflow"
					className="w-full py-2"
					icon={<CheckIcon className="w-4 h-4 fill-inflow-700" />}
					iconPosition="left"
				>
					Save product
				</Button>
				{edit ? (
					<Link
						to="/links/new"
						search={{
							...search,
							products: (search.products ?? []).filter(
								(prod) => JSON.stringify(prod) !== edit,
							),
						}}
					>
						<Button type="button" color="red" className="py-2">
							Delete
						</Button>
					</Link>
				) : null}
			</div>
		</form>
	);
}
