import { Outlet, createRoute, redirect } from "@tanstack/react-router";
import { type UseMeReturn, meOptions } from "../api/use-me";
import { NotFound } from "../components/not-found";
import { queryClient, rootRoute } from "./_root";

export const emptyLayout = createRoute({
	id: "empty",
	getParentRoute: () => rootRoute,
	component: EmptyLayout,
	loader: async ({ location }) => {
		const user = await queryClient.ensureQueryData<UseMeReturn>(meOptions());

		if (!user) {
			throw redirect({
				to: "/login",
				search: {
					next: location.href,
				},
			});
		}

		if (user?.legalName === null) {
			throw redirect({
				to: "/merchant",
				search: {
					next: location.href,
				},
			});
		}

		return {
			user,
		};
	},
	notFoundComponent: () => <NotFound className="h-[calc(100vh_-_60px)]" />,
});

function EmptyLayout() {
	return <Outlet />;
}
