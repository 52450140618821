import { Outlet, createRoute } from "@tanstack/react-router";
import { NotFound } from "../components/not-found";
import { rootRoute } from "../layouts/_root";

export const authLayout = createRoute({
	id: "_auth",
	getParentRoute: () => rootRoute,
	component: Layout,
	notFoundComponent: () => <NotFound className="h-[calc(100vh_-_60px)]" />,
});

function Layout() {
	return (
		<div className="min-h-screen flex flex-row">
			<main className="flex-1 md:min-w-[32rem] md:max-w-2xl h-screen py-8 md:py-12 flex flex-col items-center md:justify-center">
				<Outlet />
			</main>
			<div className="flex-1 relative hidden md:block">
				<img
					src="/static/bg.png"
					alt="Login background"
					className="h-full w-full object-cover"
					fetchPriority="high"
				/>
			</div>
		</div>
	);
}
