import { createRouter } from "@tanstack/react-router";
import { rootRoute } from "./layouts/_root";
import { dashLayout } from "./layouts/dash";
import { emptyLayout } from "./layouts/empty";
import { authLayout } from "./pages/_auth";
import { loginRoute } from "./pages/_auth/login";
import { otpRoute } from "./pages/_auth/otp";
import { dashBalanceRoute } from "./pages/dash/balance";
import { dashDevsRoute } from "./pages/dash/devs";
import { dashIndexRoute } from "./pages/dash/index";
import { linksRoute } from "./pages/dash/links/index";
import { dashLinkRoute } from "./pages/dash/links/link";
import { dashPaymentLinkRoute } from "./pages/dash/links/new";
import { dashPaymentsRoute } from "./pages/dash/payments/index";
import { dashPaymentRoute } from "./pages/dash/payments/payment";
import { dashPayoutsRoute } from "./pages/dash/payouts";
import { dashPayoutRoute } from "./pages/dash/payouts/payout";
import { dashProductAddRoute } from "./pages/dash/products/add";
import { dashSettingsRoute } from "./pages/dash/settings";
import { merchantRoute } from "./pages/merchant";

const routeTree = rootRoute.addChildren([
	// loginRoute,
	merchantRoute,
	dashLayout.addChildren([
		dashIndexRoute,
		dashSettingsRoute,
		dashBalanceRoute,
		dashPaymentsRoute,
		dashPaymentRoute,
		dashPayoutsRoute,
		dashPayoutRoute,
		dashDevsRoute,
		linksRoute,
		dashLinkRoute,
	]),
	authLayout.addChildren([loginRoute, otpRoute]),
	emptyLayout.addChildren([dashProductAddRoute, dashPaymentLinkRoute]),
]);

export const router = createRouter({ routeTree, defaultPreload: "intent" });

declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}
