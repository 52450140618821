import { getAccessToken } from "@privy-io/react-auth";
import {
	queryOptions,
	useQuery,
	useSuspenseQuery,
} from "@tanstack/react-query";
import { z } from "zod";
import { fetcher } from "../lib/fetcher";
import type { QueryOptions } from "./types";

type Options = QueryOptions<Awaited<ReturnType<typeof getQuote>>>;

async function getQuote() {
	const token = await getAccessToken();

	if (!token) {
		throw new Error("Unauthorized");
	}

	return fetcher(
		`${import.meta.env.VITE_API_URL}/dashboard/quote`,
		z.object({
			midmarket_rate: z.coerce.number(),
			buy_rate: z.coerce.number(),
			sell_rate: z.coerce.number(),
		}),
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		},
	);
}

export function quoteOptions(options: Options = {}) {
	return queryOptions({
		queryKey: ["quote"],
		queryFn: () => getQuote(),
		staleTime: 10 * 1000,
		...options,
	});
}

export function useQuote(options: Options = {}) {
	const { data, ...query } = useQuery(quoteOptions(options));

	return {
		quote: data,
		...query,
	};
}

export function useSuspenseQuote(options: Options = {}) {
	const { data, ...query } = useSuspenseQuery(quoteOptions(options));

	return {
		quote: data,
		...query,
	};
}
