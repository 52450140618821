import { zodResolver } from "@hookform/resolvers/zod";
import {
	useCreateWallet,
	useLoginWithEmail,
	usePrivy,
	useWallets,
} from "@privy-io/react-auth";
import {
	createRoute,
	redirect,
	useNavigate,
	useSearch,
} from "@tanstack/react-router";
import { OTPInput, REGEXP_ONLY_DIGITS, type SlotProps } from "input-otp";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useSignup } from "../../api/mutations/use-signup";
import { InflowIcon } from "../../assets/inflow";
import { Button } from "../../components/ui/button";
import { cn } from "../../lib/cn";
import { getUser } from "../../lib/get-user";
import { authLayout } from "../_auth";

const searchSchema = z.object({
	email: z.string().email(),
	next: z.string().optional(),
});

export const otpRoute = createRoute({
	getParentRoute: () => authLayout,
	path: "/otp",
	component: Page,
	loaderDeps: ({ search: { next } }) => ({ next }),
	loader: async ({ deps, context }) => {
		const user = await getUser(context.accessToken);

		if (!user) {
			return;
		}

		if (user?.legalName === null) {
			throw redirect({
				to: "/merchant",
				search: {
					next: deps.next || "/",
				},
			});
		}

		// waiting to get history -1, so this is a custom workaround to get history
		// https://github.com/TanStack/router/discussions/305#discussioncomment-3351924
		if (deps.next === "/links/new") {
			throw redirect({
				to: deps.next,
				search: {
					products: [],
					isEmailRequired: true,
					back: "/",
				},
			});
		}

		throw redirect({
			to: deps.next || "/",
		});
	},
	validateSearch: searchSchema.parse,
});

const formValuesSchema = z.object({
	code: z.string().length(6, "Invalid code"),
});

type FormValues = z.infer<typeof formValuesSchema>;

function Page() {
	const [isLoading, setIsLoading] = useState(false);
	const { next, email } = useSearch({ from: "/_auth/otp" });
	const navigate = useNavigate();
	const {
		handleSubmit,
		register,
		clearErrors,
		formState: { errors },
		setValue,
		setError,
	} = useForm<FormValues>({
		resolver: zodResolver(formValuesSchema),
		mode: "onSubmit",
		reValidateMode: "onChange",
	});
	const { mutate } = useSignup({
		onSuccess: () => {
			navigate({
				to: next || "/merchant",
			});

			setIsLoading(false);
		},
	});
	const { ready, authenticated, user } = usePrivy();
	const { wallets } = useWallets();
	const { createWallet } = useCreateWallet();
	const { sendCode, loginWithCode, state } = useLoginWithEmail({
		onComplete: async (_user, isNewUser) => {
			if (!isNewUser) {
				return navigate({
					to: next || "/",
				});
			}

			// Handle account creation in `useEffect`
		},
		onError: (error) => {
			if (error === "unknown_auth_error") {
				navigate({
					to: "/login",
					search: { next },
				});
			} else if (error === "invalid_credentials") {
				setError("code", {
					message: "Code invalid",
				});
			} else if (error === "invalid_data") {
				setError("code", {
					message: "Code expired",
				});
			} else {
				setError("code", {
					message: "Invalid code",
				});
			}

			setIsLoading(false);
		},
	});

	useEffect(() => {
		if (state.status === "initial") {
			navigate({
				to: "/login",
				search: { next },
			});
		}
	}, [state.status, navigate, next]);

	const createWalletAndSignup = useCallback(
		async (email: string) => {
			if (authenticated) {
				const wallet = await createWallet();

				mutate({
					email,
					wallet: wallet.address,
				});
			}
		},
		[authenticated, mutate, createWallet],
	);

	useEffect(() => {
		const embeddedWallet = wallets.find(
			(wallet) => wallet.walletClientType === "privy",
		);

		if (authenticated && !embeddedWallet && user?.email) {
			createWalletAndSignup(user.email.address);
		}
	}, [authenticated, user?.email, wallets, createWalletAndSignup]);

	function onSubmit({ code }: FormValues) {
		setIsLoading(true);

		loginWithCode({ code });
	}

	return (
		<div className="w-full px-4 md:max-w-md flex flex-col items-start space-y-12">
			<InflowIcon className="w-20 h-8 fill-black" />
			<form
				className="w-full flex flex-col space-y-4"
				onSubmit={handleSubmit(onSubmit)}
			>
				<h1 className="text-xl font-medium">We sent a code to your email</h1>
				<div className="flex flex-col space-y-1">
					<label
						htmlFor="code"
						className="text-sm text-neutral-700 font-medium"
					>
						OTP Code
					</label>
					<OTPInput
						id="code"
						containerClassName="flex items-center gap-3 has-[:disabled]:opacity-50"
						maxLength={6}
						pattern={REGEXP_ONLY_DIGITS}
						autoFocus
						{...register("code")}
						// onChange is different one from native, so we need to manually do it
						onChange={(val) => {
							setValue("code", val);
							clearErrors("code");
						}}
						onComplete={() => {
							handleSubmit(onSubmit)();
						}}
						render={({ slots }) => (
							<>
								<div className="flex">
									{slots.slice(0, 3).map((slot, idx) => (
										// biome-ignore lint/suspicious/noArrayIndexKey: no other choice
										<Slot key={idx} {...slot} />
									))}
								</div>

								<div className="flex w-4 justify-center items-center">
									<div className="w-2 h-0.5 rounded-full bg-border bg-neutral-200" />
								</div>

								<div className="flex">
									{slots.slice(3).map((slot, idx) => (
										// biome-ignore lint/suspicious/noArrayIndexKey: no other choice
										<Slot key={idx} {...slot} />
									))}
								</div>
							</>
						)}
					/>
					{errors.code && (
						<span className="text-sm text-red-500">{errors.code.message}</span>
					)}
				</div>
				<Button
					type="submit"
					color="inflow"
					intent="solid"
					disabled={!ready}
					className="w-full !py-2"
				>
					{state.status === "submitting-code"
						? "Verifying code..."
						: isLoading
							? "Loading..."
							: "Login"}
				</Button>
				<span className="text-xs text-neutral-500 font-medium">
					Haven't received a code?{" "}
					<button
						className="underline"
						type="button"
						onClick={() => sendCode({ email })}
					>
						{state.status === "sending-code" ? "Sending..." : "Send again"}
					</button>
				</span>
			</form>
			<span className="text-xs text-neutral-200">
				App version: {import.meta.env.MODE}.{import.meta.env.COMMIT_HASH}
			</span>
		</div>
	);
}

function Slot(props: SlotProps) {
	return (
		<div
			className={cn(
				"relative -ms-px flex size-9 items-center justify-center border border-input bg-background font-medium text-foreground shadow-sm shadow-black/5 transition-shadow first:ms-0 first:rounded-s-lg last:rounded-e-lg",
				props.isActive &&
					"z-10 border border-ring ring-[3px] ring-inflow-700/40",
			)}
		>
			{props.char !== null && <div>{props.char}</div>}
		</div>
	);
}
