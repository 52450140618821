import { getAccessToken } from "@privy-io/react-auth";
import { useMutation } from "@tanstack/react-query";
import type { MutationOptions } from "../types";

type Data = {
	email: string;
	wallet: string;
};

type Options = MutationOptions<Awaited<ReturnType<typeof fn>>, Data>;

async function fn({ email, wallet }: Data) {
	// We use fetch as fetcher uses JSON.parse(), but no JSON is returned here
	const { ok } = await fetch(`${import.meta.env.VITE_API_URL}/auth/signup`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${await getAccessToken()}`,
		},
		credentials: "include",
		body: JSON.stringify({
			email,
			walletAddress: wallet,
		}),
	});

	if (!ok) {
		throw new Error("Failed to signup");
	}
}

export function useSignup(options: Options = {}) {
	return useMutation({
		mutationKey: ["signup"],
		mutationFn: fn,
		...options,
	});
}
