import { Link, createRoute, useSearch } from "@tanstack/react-router";
import { z } from "zod";
import { ChevronIcon } from "../../../assets/chevron";
import { AddProductForm } from "../../../components/products/add-product-form";
import { emptyLayout } from "../../../layouts/empty";

const searchSchema = z.object({
	products: z
		.array(
			z.object({
				description: z.string(),
				amount: z.number(),
				quantity: z.number().default(1),
			}),
		)
		.optional(),
	isEmailRequired: z.boolean(),
	edit: z.string().optional(),
});

export const dashProductAddRoute = createRoute({
	getParentRoute: () => emptyLayout,
	path: "/products/add",
	component: ProductAddPage,
	validateSearch: searchSchema.parse,
});

function ProductAddPage() {
	const search = useSearch({
		from: "/empty/products/add",
	});

	return (
		<div className="py-4 md:py-6 px-4 md:px-8 w-full flex flex-col space-y-6">
			<div className="flex items-center justify-between">
				<div className="flex items-center space-x-4">
					<Link
						to="/links/new"
						search={{
							products: [],
							back: "/",
							...search,
						}}
					>
						<ChevronIcon className="w-4 h-4 fill-neutral-600 rotate-90" />
					</Link>
					<div className="h-4 w-px bg-neutral-200" />
					<h1 className="text-neutral-600 font-medium">Add a product</h1>
				</div>
			</div>
			<AddProductForm />
		</div>
	);
}
