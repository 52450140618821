import {
	Link,
	createRoute,
	useNavigate,
	useSearch,
} from "@tanstack/react-router";
import {
	createColumnHelper,
	getCoreRowModel,
	useReactTable,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import { useMemo } from "react";
import { z } from "zod";
import { useLinks } from "../../../api/use-links";
import { NoData } from "../../../components/charts/no-data";
import { Loading } from "../../../components/loading";
import { StatusPill } from "../../../components/payment/status-pill";
import { Button } from "../../../components/ui/button";
import { Table } from "../../../components/ui/table";
import { dashLayout } from "../../../layouts/dash";
import { numberFormatter } from "../../../lib/number";
import type { Link as TLink } from "../../../schemas/link";

const searchSchema = z.object({
	page: z.number().optional().default(1),
});

export const linksRoute = createRoute({
	getParentRoute: () => dashLayout,
	path: "/links",
	component: LinksPage,
	validateSearch: searchSchema,
});

function LinksPage() {
	const { page } = useSearch({ from: "/dash/links" });
	const navigate = useNavigate({ from: "/links" });
	const { links, pagination, isLoading } = useLinks({
		page,
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: refresh on status and page change
	const data = useMemo(() => {
		if (!links) {
			return [];
		}

		return links;
	}, [page]);

	const columnHelper = createColumnHelper<TLink>();

	const columns = [
		columnHelper.accessor("amount", {
			header: "Amount",
			cell: ({ getValue, row }) => (
				<Link
					to="/link/$id"
					params={{
						id: row.original.id,
					}}
					className="w-full h-full flex space-x-1"
				>
					{numberFormatter(getValue() / 100, {
						style: "currency",
						currency: row.original.currency,
					})}
				</Link>
			),
		}),
		columnHelper.accessor("status", {
			header: "Status",
			cell: ({ getValue, row }) => (
				<Link
					to="/link/$id"
					params={{
						id: row.original.id,
					}}
					className="block w-full h-full"
				>
					<StatusPill status={getValue()} />
				</Link>
			),
		}),
		columnHelper.accessor("createdAt", {
			header: "Created at",
			cell: ({ getValue, row }) => (
				<Link
					to="/link/$id"
					params={{
						id: row.original.id,
					}}
					className="block w-full h-full whitespace-nowrap truncate"
				>
					{dayjs(getValue()).format("MMM DD, H:mm a")}
				</Link>
			),
		}),
	];

	const table = useReactTable({
		columns,
		data: (links.length || 0) > data.length ? links : data,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<div className="md:py-6 px-6 md:px-12 w-full flex flex-col space-y-4">
			<div className="flex items-center justify-between">
				<h1 className="font-semibold text-2xl">Links</h1>
				<Link
					to="/links/new"
					search={{
						products: [],
						isEmailRequired: true,
					}}
				>
					<Button intent="solid" color="inflow">
						Create link
					</Button>
				</Link>
			</div>
			<Table table={table}>
				<table className="w-full table-fixed">
					<Table.Header />
					{!isLoading && <Table.Body />}
				</table>
				{isLoading && <Loading className="w-full py-36" />}
				{!isLoading && table.getRowModel().rows.length === 0 && (
					<NoData className="m-4" />
				)}
			</Table>
			<div className="w-full py-2 flex flex-col md:flex-row justify-center items-center space-y-4 md:space-x-4 md:space-y-0">
				<div className="flex justify-center items-center space-x-4">
					<Button
						intent="outline"
						className="border rounded p-1"
						onClick={() =>
							navigate({
								from: linksRoute.to,
								to: linksRoute.to,
								search: (current) => ({
									...current,
									page: 1,
								}),
							})
						}
						disabled={page <= 1}
					>
						{"<<"}
					</Button>
					<Button
						intent="outline"
						className="border rounded p-1"
						onClick={() =>
							navigate({
								from: linksRoute.to,
								to: linksRoute.to,
								search: (current) => ({
									...current,
									page: pagination.currentPage - 1,
								}),
							})
						}
						disabled={page <= 1}
					>
						{"<"}
					</Button>
					<Button
						intent="outline"
						className="border rounded p-1"
						onClick={() =>
							navigate({
								from: linksRoute.to,
								to: linksRoute.to,
								search: (current) => ({
									...current,
									page: pagination.currentPage + 1,
								}),
							})
						}
						disabled={page >= pagination.lastPage}
					>
						{">"}
					</Button>
					<Button
						intent="outline"
						className="border rounded p-1"
						onClick={() =>
							navigate({
								from: linksRoute.to,
								to: linksRoute.to,
								search: (current) => ({
									...current,
									page: pagination.lastPage,
								}),
							})
						}
						disabled={page >= pagination.lastPage}
					>
						{">>"}
					</Button>
				</div>
				<div className="flex justify-center items-center space-x-4">
					<span className="text-sm text-neutral-800">
						Page {pagination.currentPage ?? 0} out of {pagination.lastPage ?? 0}
					</span>
					<span className="text-sm text-neutral-800">
						{pagination.total ?? 0} total results
					</span>
				</div>
			</div>
		</div>
	);
}
