import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { CircleXIcon } from "../../assets/circle-x";
import { XMarkIcon } from "../../assets/xmark";
import { cn } from "../../lib/cn";
import type { PaymentsSearchSchema } from "../../pages/dash/payments/index";
import { ModalContext } from "../../providers/modal";
import { Button } from "../ui/button";

type SavedFilterData = {
	name: string;
	search: PaymentsSearchSchema;
};

export function SavedFilter() {
	const ctx = useContext(ModalContext);
	const navigate = useNavigate();
	const { page, ...search } = useSearch({ from: "/dash/payments" });
	const [filter, saveFilter] = useLocalStorage<SavedFilterData | null>(
		"payment-filter",
		null,
	);

	const hasFiltersApplied = useMemo(
		() => Object.values(search).length > 0,
		[search],
	);

	return (
		<div
			className={cn(
				"py-1 flex items-center divide-x divide-neutral-200 md:relative border border-neutral-300 rounded-full cursor-pointer transition-transform duration-200",
				!filter && "border-dashed hover:bg-neutral-50",
				!hasFiltersApplied && !filter && "opacity-50",
			)}
			onClick={(e) => {
				e.preventDefault();

				if (!hasFiltersApplied && !filter) {
					return;
				}

				if (!filter) {
					ctx?.open(<SaveFilterModal />);
				} else {
					navigate({
						to: "/payments",
						search: {
							page: 1,
							...filter.search,
						},
					});
				}
			}}
		>
			<div className="px-1.5 flex space-x-1">
				<button
					type="button"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();

						if (!hasFiltersApplied && !filter) {
							return;
						}

						if (!filter) {
							ctx?.open(<SaveFilterModal />);
						} else {
							ctx?.open(
								<ConfirmDeleteModal
									onConfirm={() => {
										saveFilter(null);
										ctx.close();
									}}
								/>,
							);
						}
					}}
				>
					<CircleXIcon
						className={cn(
							"w-3.5 h-3.5 fill-neutral-600 transition-transform duration-200",
							!filter && "rotate-45",
						)}
					/>
				</button>
				<span className="text-xs font-semibold text-neutral-600">
					{filter ? filter.name : "Save current filters"}
				</span>
			</div>
		</div>
	);
}

const formValuesSchema = z.object({
	name: z.string().min(3, "Label must have at least 3 characters"),
});

type FormValues = z.infer<typeof formValuesSchema>;

export function SaveFilterModal() {
	const ctx = useContext(ModalContext);
	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<FormValues>({
		resolver: zodResolver(formValuesSchema),
		mode: "onSubmit",
		reValidateMode: "onChange",
	});
	const { page, ...search } = useSearch({ from: "/dash/payments" });
	const [_filter, saveFilter] = useLocalStorage<SavedFilterData | null>(
		"payment-filter",
		null,
	);

	function onSubmit(data: FormValues, e?: React.BaseSyntheticEvent) {
		e?.preventDefault();

		saveFilter({ name: data.name, search });

		ctx?.close();
	}

	return (
		<form
			className="w-full max-w-lg flex flex-col space-y-8 bg-white rounded-md"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="pt-4 px-6 flex justify-between items-center">
				<span className="font-medium">Add a label for the filter</span>
				<button type="button" onClick={() => ctx?.close()}>
					<XMarkIcon className="w-4 h-4" />
				</button>
			</div>
			<div className="px-6 flex flex-col items-center space-y-4">
				<div className="w-full flex flex-col space-y-1">
					<div className="flex items-center justify-between">
						<label htmlFor="name" className="text-sm text-neutral-700">
							Label
						</label>
						{errors.name && (
							<span className="text-sm text-red-500">
								{errors.name.message}
							</span>
						)}
					</div>
					<input
						id="name"
						type="text"
						placeholder="Authorized USD payments"
						className="w-full px-3 py-1.5 text-sm border border-neutral-200 hover:border-neutral-300 focus:border-neutral-300 outline-none rounded-md transition-colors duration-300 ease-in-out"
						{...register("name")}
					/>
				</div>
			</div>
			<div className="pb-4 px-6 flex space-x-4 justify-end items-center">
				<Button onClick={() => ctx?.close()}>Cancel</Button>
				<Button type="submit" color="inflow" intent="solid">
					Save
				</Button>
			</div>
		</form>
	);
}

type ConfirmDeleteModalProps = {
	onConfirm: () => void;
};

export function ConfirmDeleteModal({ onConfirm }: ConfirmDeleteModalProps) {
	const ctx = useContext(ModalContext);

	return (
		<div className="w-full max-w-lg flex flex-col space-y-8 bg-white rounded-md">
			<div className="pt-4 px-6 flex justify-between items-center">
				<span className="font-medium">
					Are you sure you want to delete this filter?
				</span>
				<button type="button" onClick={() => ctx?.close()}>
					<XMarkIcon className="w-4 h-4" />
				</button>
			</div>
			<div className="pb-4 px-6 flex space-x-4 justify-end items-center">
				<Button onClick={() => ctx?.close()}>Cancel</Button>
				<Button type="submit" color="red" intent="outline" onClick={onConfirm}>
					Delete
				</Button>
			</div>
		</div>
	);
}
